import { Affix, Col, Empty, Row, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { setLocationHref } from '../../utils';
import { BlankSegment, DynamicProductCard, Footer, FooterLogo, Navigation, PaginationCus, RightSider, VendorRelated } from '../components';
import styles from './index.less';
import Store from './store';
import type { DynamicGoods } from './types';

const store = new Store();

const Vendors: React.FC<{ store: Store; }> = observer((props) => {
  useEffect(() => {
    setLocationHref();
  }, []);

  const {
    onTabChange,
    paginationStore,
    tabs,
    conditions,
    renderList,
    currentTabKey,
    pageLoading,
    filters,
    affixed,
    rightSiderStore,
    onAffixChange,
  } = props.store;

  const renderGoodsList = useCallback((goodsList: DynamicGoods[]) => {
    return (
      <Row gutter={[
        12,
        0,
      ]}
      >
        {
          goodsList?.map((el) => {
            return (
              <Col key={el.goodsId}>
                <DynamicProductCard
                  discountPrice={el.discountPrice}
                  goodsId={el.goodsId}
                  mainPicUrl={el.mainPicUrl}
                  picGroupNum={el.picGroupNum}
                  price={el.price}
                  visibleType={el.visibleType}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }, []);

  return (
    <>
      <Navigation className={styles.navigation}/>
      <div
        className={styles.pageContainer}
        id="page"
      >
        <Affix onChange={onAffixChange}>
          <div className={affixed ? `${styles.affix} ${styles.affixedStyle}` : `${styles.affix}`}>
            <Tabs
              className={styles.tabs}
              defaultActiveKey="cooperation"
              items={tabs}
              onChange={onTabChange}
              tabBarGutter={16}
            />
          </div>
        </Affix>
        <div className={styles.filter}>
          {filters[currentTabKey]}
        </div>
        <Spin spinning={pageLoading}>
          {
            pageLoading ? <BlankSegment/> : (
              <>
                <div className={styles.divider}/>
                <div className={styles.vendorContainer}>
                  {renderList[currentTabKey].vendorList?.map((el) => {
                    return (
                      <VendorRelated
                        discountPercent={el.discountPer}
                        goodsPutOnNumWeek={currentTabKey === 'cooperation' ? el.goodsPutOnNumWeek : el.goodsPutOnNum}
                        key={el.goodsVendorId}
                        labelList={el.tags}
                        listContent={renderGoodsList(el.goodsList)}
                        mainCategoryName={el.mainCategoryName}
                        showLabel={currentTabKey === 'cooperation'}
                        showNameEnterIcon
                        type="detail"
                        vendorId={el.goodsVendorId}
                        vendorName={el.goodsVendorName}
                      />
                    );
                  })}
                </div>
                {!renderList[currentTabKey].vendorList?.length && (
                  <div className={styles.empty}>
                    <Empty/>
                  </div>
                )}
                <PaginationCus
                  {...renderList[currentTabKey]}
                  pageSize={conditions[currentTabKey].pageSize}
                  store={paginationStore}
                />

              </>
            )
          }
        </Spin>
        <RightSider store={rightSiderStore}/>
      </div>
      <FooterLogo/>
      <Footer/>
    </>
  );
});

const VendorsContainer: React.FC<unknown> = observer((props) => {
  return <Vendors store={store}/>;
});

export default VendorsContainer;
