import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable, set } from 'mobx';
import React from 'react';
import { api, scrollToAnchor } from '../../utils';
import { Filter, FilterStore } from '../components';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import type { AllVendor, IMainCategory, LabelList, Option, RenderItem } from './types';

export default class Store {
  constructor() {
    this.init();
  }

  @observable public pageLoading = false;

  @observable public affixed = false; // 是否已经吸顶，用来判断样式

  @observable public paginationStore = new PaginationStore({ parent: this });

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public allFilterStore = new FilterStore({
    filterItems: [
      {
        type: 'select',
        field: 'sidx',
        data: [
          {
            label: '选款降序',
            value: 'goodsSelectedNumTotal',
          },
          {
            label: '上新降序',
            value: 'goodsPutOnNumWeek',
          },
        ],
        value: 'goodsSelectedNumTotal',
      },
      {
        type: 'select',
        field: 'mainCategoryId',
        data: [
          {
            label: '全部主营',
            value: 0,
          },
        ],
        value: 0,
      },
    ],
    handleSearch: () => {
      this.onConditionChange(this.allFilterStore.params);
    },
  });

  @observable public cooperationFilterStore = new FilterStore({
    filterItems: [
      {
        type: 'select',
        field: 'vendorTagId',
        data: [
          {
            label: '全部合作档口',
            value: 0,
          },
        ],
        value: 0,
      },
      {
        type: 'select',
        field: 'sidx',
        data: [
          {
            label: '选款降序',
            value: 'goodsSelectedNumTotal',
          },
          {
            label: '上新降序',
            value: 'goodsPutOnNumWeek',
          },
        ],
        value: 'goodsSelectedNumTotal',
      },
      {
        type: 'select',
        field: 'mainCategoryId',
        data: [
          {
            label: '全部主营',
            value: 0,
          },
        ],
        value: 0,
      },
    ],
    handleSearch: () => {
      this.onConditionChange(this.cooperationFilterStore.params);
    },
  });

  @observable public currentTabKey: 'all' | 'cooperation' = 'cooperation'; // 当前tabKey

  @observable public labelOptions: Option[] = []; // 标签列表

  @observable public tabs = [
    {
      label: '合作档口',
      key: 'cooperation',
      filters: <Filter store={this.cooperationFilterStore}/>,
    },
    {
      label: '全部档口',
      key: 'all',
      filters: <Filter store={this.allFilterStore}/>,
    },
  ];

  public filters = {
    all: <Filter store={this.allFilterStore}/>,
    cooperation: <Filter store={this.cooperationFilterStore}/>,
  };

  // list
  @observable public renderList: Record<'all' | 'cooperation', RenderItem> = {
    all: {
      totalCount: 0,
      totalPageCount: 1,
      vendorList: [],
    },
    cooperation: {
      totalCount: 0,
      totalPageCount: 1,
      vendorList: [],
    },
  };

  // 请求条件
  @observable public conditions = {
    all: {
      sidx: 'goodsSelectedNumTotal',
      mainCategoryId: 0,
      current: 1,
      pageSize: 10,
    },
    cooperation: {
      sidx: 'goodsSelectedNumTotal',
      mainCategoryId: 0,
      current: 1,
      pageSize: 10,
      vendorTagId: 0,
    },
  };

  private init = () => {
    this.setPager();
    this.queryAllVendor();
    this.queryCooperationVendor();
    this.queryLabelList();
    this.queryMainCategory();
  };

  @action public onTabChange = (activeKey: 'all' | 'cooperation') => {
    this.currentTabKey = activeKey;
    this.setPager();
    this.backTop();
  };

  // 获取标签列表
  private queryLabelList = async(): Promise<void> => {
    const res: BaseData<LabelList> = await request({ url: api.getLabelList });
    const options = res?.data?.map((el) => {
      return {
        label: el.tagName,
        value: el.id.toString(),
      };
    });
    this.cooperationFilterStore.updateFilterItems({
      vendorTagId: [
        {
          label: '全部合作档口',
          value: 0,
        },
        ...options,
      ],
    });
  };

  // 获取全部主营
  private queryMainCategory = async() => {
    const res = await request<BaseData<IMainCategory>>({ url: api.queryCategory });

    const options = res.data?.layoutDetailList?.map((item) => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
    this.allFilterStore.updateFilterItems({
      mainCategoryId: [
        {
          label: '全部主营',
          value: 0,
        },
        ...options,
      ],
    });
    this.cooperationFilterStore.updateFilterItems({
      mainCategoryId: [
        {
          label: '全部主营',
          value: 0,
        },
        ...options,
      ],
    });
  };

  // 筛选项改变
  public onConditionChange = (params: Array<Record<string, string | number | string[] | number[]>>) => {
    Object.assign(this.conditions[this.currentTabKey], {
      current: 1,
      pageSize: 10,
      ...params,
    });
    this.setPager();
    this.backTop();
    this.onPageConditionChange();
  };

  // 请求全部档口
  @action public queryAllVendor = async(): Promise<void> => {
    this.pageLoading = true;
    const data = this.getCondition();
    const res: PaginationData<AllVendor> = await request({
      url: api.getPageMall,
      method: 'post',
      data,
    });
    this.renderList.all.vendorList = res.data.list;
    Object.assign(this.renderList.all, {
      totalCount: res?.data?.totalCount || 0,
      totalPageCount: res?.data?.totalPageCount || 1,
    });
    this.pageLoading = false;
  };

  // 请求合作档口
  @action public queryCooperationVendor = async(): Promise<void> => {
    this.pageLoading = true;
    const data = this.getCondition();
    const res: PaginationData<AllVendor> = await request({
      url: api.cooperationStall,
      method: 'post',
      data,
    });
    this.renderList.cooperation.vendorList = res.data.list;
    Object.assign(this.renderList.cooperation, {
      totalCount: res?.data?.totalCount || 0,
      totalPageCount: res?.data?.totalPageCount || 1,
    });
    this.pageLoading = false;
  };

  // 分页页数改变的回调
  @action public onShowSizeChange = (page: number, pageSize: number) => {
    Object.assign(this.conditions[this.currentTabKey], {
      current: page,
      pageSize,
    });

    this.backTop();
    this.onPageConditionChange();
  };

  // 分页改变回调
  @action public onPageChange = (page: number, pageSize: number) => {
    Object.assign(this.conditions[this.currentTabKey], {
      current: page,
      pageSize,
    });

    this.backTop();
    this.onPageConditionChange();
  };

  // init分页器
  @action private setPager = () => {
    set(this.paginationStore, {
      current: this.conditions[this.currentTabKey].current,
      pageSize: this.conditions[this.currentTabKey].pageSize,
    });
  };

  // 获取条件
  private getCondition = (): Record<string, string | number> => {
    const conditions = {
      ...this.conditions[this.currentTabKey],
      page: 1,
    };
    for (const key in conditions) {
      if (Object.prototype.hasOwnProperty.call(conditions, key)) {
        const element = conditions[key];
        if (!element || key === 'totalCount' || key === 'totalPageCount') {
          delete conditions[key];
        }

        if (key === 'current') {
          delete conditions.current;
          conditions.page = element;
        }
      }
    }
    return conditions;
  };

  // 条件改变
  public onPageConditionChange = (): void => {
    if (this.currentTabKey === 'all') {
      this.queryAllVendor();
    } else {
      this.queryCooperationVendor();
    }
  };

  // 回到顶部
  private backTop = (): void => {
    scrollToAnchor('root');
  };

  // 跳转到款式详情
  public jumpToGoodsDetail = (goodsId: number, dynamicId?: number | string): void => {
    window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}&dynamicId=${dynamicId || ''}`);
  };

  // 跳转到档口详情
  public jumpToVendorDetail = (vendorId: number, dynamicId: number | string): void => {
    window.open(`/egenie-ts-vogue/stallDetail/index?vendorId=${vendorId}&dynamicId=${dynamicId || ''}`);
  };

  @action // 吸顶状态改变
  public onAffixChange = (affixed: boolean) => {
    this.affixed = affixed;
  };
}

